<template>
    <div class="article-box" v-loading="loading">
        <div class="page-nav clearfix">
            <ul>
                <li>
                    <router-link class="nav-item" to="/institution/branch/msg">
                        交流讨论&nbsp;&nbsp;/
                    </router-link>
                </li>
                <li class = "nav-item nav-item-last">
                    &nbsp;&nbsp;添加新消息
                </li>
            </ul>
        </div>
        <!-- 标题 -->
        <div class="pag-title clearfix">
            <div class="page-title-name pull-left">添加新消息</div>
        </div>
        <div class="form-box">
          <el-form ref="form" label-width="100px">
            <el-form-item label="发送用户">
              <el-radio-group v-model="userType" @change="userTypeChange">
                <el-radio label="1">管理员</el-radio>
                <el-radio label="2">单位会员</el-radio>
                <el-radio label="3">个人会员</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label=" " v-if="isShowTable">
              <el-input v-model="searchKey" style="width: 280px" size="small"></el-input>
              <el-button type="primary" style="margin-left: 20px" size="small" @click="searchUser()">查询</el-button>
            </el-form-item>
            <el-form-item label=" "  v-if="isShowTable">
                <el-table
                  v-loading="tableLoading"
                  ref="userList"
                  :data="userList"
                  highlight-current-row
                  max-height="300"
                  style="width: 100%">
                  <el-table-column
                    property="loginName"
                    label="账号"
                    width="120">
                  </el-table-column>
                  <el-table-column
                    property="unitName"
                    label="单位全称"
                    width="120">
                  </el-table-column>
                  <el-table-column
                    property="phone"
                    label="绑定手机号">
                  </el-table-column>
                  <el-table-column
                    property="email"
                    label="绑定邮箱">
                  </el-table-column>
                  <el-table-column
                    label="会员等级">
                    <template slot-scope="scope">
                      {{showOrgLevel(scope.row.orgLevel)}}
                    </template>
                  </el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button
                        size="mini"
                        type="danger"
                        v-if="receiveUserId == scope.row.id"
                        @click="delUser(scope.$index, scope.row)">删除</el-button>
                        <el-button
                        size="mini"
                        type="primary"
                        v-else
                        @click="chanceUser(scope.$index, scope.row)">选中</el-button>
                    </template>
                  </el-table-column>
                </el-table>
            </el-form-item>
            <el-form-item label="已选用户">
              <el-tag
                closable
                @close="delUser()"
                v-if="receiveUserId">
                {{receiveUserName}}
              </el-tag>
            </el-form-item>
            <el-form-item label="消息内容">
              <el-input type="textarea" v-model="msgContent"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div style="margin: 20px" class="clearfix">
          <div class="button-item button-search pull-right" @click="sureHandler()"  style="margin-left: 20px">确定</div>
          <div class="button-item button-cancle pull-right" @click="cancelHandler()">取消</div>
        </div>
    </div>
</template>
<script>
import {
  BranchMsgAdd,
  GetMemberList,
} from "@/http/api/InitiationApi";
import {showOrgLevel} from '@/utils/util'

export default {
  name: "",
  props: {},
  components: {},
  data () {
    return {
      loading: false,
      searchKey: '',
      userType: '',
      userList: [],
      msgContent: '',
      isShowTable: false,
      tableLoading: false,
      receiveUserId: '',
      receiveUserName: ''
    };
  },
  computed: {},
  created() {
    this.isShowTable = false
  },
  methods: {
   userTypeChange (val) {
     if (val != 1) {
       this.receiveUserId = '';
       this.receiveUserName = '';
       this.tableLoading = true;
       this.isShowTable = true;
       GetMemberList({
         form: val == 2 ? 2 : 1,
         keyword: this.searchKey
       }).then(resp => {
         this.tableLoading = false ;
         if (resp.code == '000') {
           this.userList = resp.result;
         } else {
           this.userList = [];
         }
       })
     } else {
       this.isShowTable = false;
       this.receiveUserId = 1;
       this.receiveUserName = '管理员'
     }
   },
   searchUser () {
     this.userTypeChange(this.userType)
   },
   chanceUser (index, row) {
     this.receiveUserId = row.id
     this.receiveUserName = row.unitName
   },
   delUser (index, row) {
     this.receiveUserId = ''
     this.receiveUserName = ''
   },
   sureHandler () {
      let item = {}
      if (this.receiveUserId && this.msgContent) {
        item.receiveUserId = this.receiveUserId;
        item.content = this.msgContent;
        BranchMsgAdd(item).then(resp => {
        if (resp.code == '000') {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          setTimeout(()=>{
            this.$router.go(-1)
          },1000)
        } else {
          this.$message({
            message: '操作失败',
            type: 'error'
          });
        }
      })
    } else {
      this.$message({
        message: '数据不得为空',
        type: 'error'
      });
    } 
      
      

   },
   cancelHandler () {
   },
   showOrgLevel (val) {
     return showOrgLevel(val)
   }
  },
};
</script>
<style lang='less' scoped>
.form-box {
  padding-top: 30px;
}
</style>